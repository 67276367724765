import React from 'react'
import { Layout } from '../components/Layout'
import { Link } from "gatsby"

const NotFoundPage = () => (
    <Layout>
        <div style={ { display: "flex", alignItems: "center", justifyContent: "center" } }>
            <div>
                <h1>お探しのページは存在しません</h1>
            </div>
            <div>
                コツ一覧はこちら
            </div>
            <div>
                <Link to="/tips">コツ一覧</Link>
            </div>
            <div>
                <p>リンク切れの場合は、お問い合わせフォームからご連絡くださると幸いです</p>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
